// Import styles
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import "~bootstrap/scss/bootstrap";
@import "./variables.module.scss";
// default css
body {
  margin: 0;
  font-family: $font-family;
  background-color: $body-color;
  color: $theme-custom-color;
}
ul {
  padding: 0;
  margin-bottom: 0;
  li {
    list-style: none;
  }
}
a {
  text-decoration: none;
  color: $theme-color;
  &:hover {
    color: $theme-color;
  }
}
h1 {
  font-size: 46px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
  &.smallSize {
    font-size: 22px;
  }
}
h4 {
  font-size: 21px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 15px;
}
.title {
  color: $theme-custom-color;
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;
  margin-bottom: 24px;
  @include extra_large {
    font-size: 35px;
    line-height: 42px;
  }
  @include mobile_device {
    font-size: 30px;
    line-height: 38px;
    text-align: center;
  }
}
img {
  max-width: 100%;
  height: auto;
}
.modal-open {
  overflow: auto !important;
  padding-right: 0 !important;
}
.desc {
  color: $desc-font;
  font-size: 16px;
  line-height: 28px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  &:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.showText {
  color: $secondary-color;
  display: block;
}
.errorMsg {
  font-size: 13px;
  color: #e50f36;
  margin-bottom: 0;
  margin-top: 2px;
  line-height: 20px;
}
.successMsg {
  font-size: 13px;
  color: green;
  margin-bottom: 0;
  margin-top: 2px;
}

.form-group {
  margin-bottom: 20px;
}

.p-relative {
  position: relative;
}
.close {
  position: absolute;
  cursor: pointer;
  right: 20px;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  @media screen and (max-width: 767px) {
    width: 14px;
    right: 15px;
  }
}
.container {
  @include large_device {
    max-width: 100%;
  }
}
// section css
section {
  padding-top: 80px;
  padding-bottom: 80px;
  @include extra_large {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @include medium_device {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.text-left {
    .section-title {
      &::before {
        margin: 0;
      }
    }
  }
  .section-title {
    font-size: 36px;
    line-height: 44px;
    font-weight: 700;
    margin-bottom: 16px;
    position: relative;
    @include large_device {
      font-size: 28px;
      line-height: 36px;
    }
    @include mobile_device {
      font-size: 22px;
      line-height: 30px;
    }
    p {
      display: inline-block;
      margin-bottom: 0;
    }
  }
  p {
    color: $desc-font;
    line-height: 28px;
  }
  .desc {
    color: $desc-font;
    font-size: 16px;
    width: 70%;
    margin: 0 auto 36px;
    line-height: 28px;
    @include large_device {
      width: 100%;
    }
    @include mobile_device {
      margin-bottom: 30px;
      font-size: 14px;
    }
  }
}

// button css
.btn {
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  z-index: 0;
  width: 240px;
  border-radius: $border-radius !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &[disabled] {
    border-color: transparent;
  }
  @include extra_large {
    padding: 12px 20px;
  }
  @include mobile_device {
    width: 100%;
  }
  &:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &.theme-btn {
    background: $theme-color;
    color: $white-color;
    &:hover, &:focus {
      background-color: #4267d7;
      border-color: #4267d7;
      color: $white-color;
    }
  }
  &.secondary-btn {
    background-color: $secondary-color;
    color: $theme-color;
    &:hover, &:focus {
      background-color: #ffe915;
      border-color: #ffe915;
      color: $theme-color;
    }
  }
  &.outline-btn {
    background-color: transparent;
    border-color: $theme-color;
    color: $theme-color;
    &:hover, &:focus {
      background-color: $theme-color;
      color: $white-color;
      border-color: $theme-color;
      svg {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        path {
          stroke: $white-color;
        }
      }
    }
  }
  & + .btn {
    margin-left: 20px;
    @include large_device {
      margin-left: 10px;
    }
  }
  &.outlineSecondaryBtn {
    color: $secondary-color;
    border-color: $secondary-color;
    background-color: $theme-color;

    &:hover, &:focus {
      background-color: $secondary-color;
      color: $theme-color;
      border-color: $secondary-color;
    }
  }
}

// dropdown menu css
.dropdown-menu {
  min-width: 200px;
  border: none;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  padding: 0;
  &::-webkit-scrollbar {
    width: 5px;
    background: #f2f6f8;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c6c7;
    border-radius: $border-radius;
  }
  .dropdown-item {
    color: $black-color;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    border-radius: 4px;
    @include mobile_device {
      font-size: 14px;
    }
    a,
    span {
      padding: 10px 15px;
      color: $theme-custom-color;
      font-weight: 400;
      font-size: 16px;
      display: block;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      @include mobile_device {
        padding: 8px 15px;
        font-size: 14px;
      }
      &.active {
        background-color: #f2f6f8;
      }
    }
    &:hover,
    &:active {
      background-color: #f2f6f8;
      font-weight: 500;
    }
  }
}
.text-white {
  color: $white-color;
}
// form control css
textarea {
  resize: none;
  border-radius: 20px !important;
}
.form-control {
  font-size: 12px;
  color: $black-color;
  border: none;
  font-weight: 500;
  &::placeholder {
    color: $desc-font;
    font-weight: 400;
  }
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
//react table
.react-bootstrap-table {
  background-color: $white-color;
  -webkit-box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
  box-shadow: 5px 8px 30px rgba(61, 90, 112, 0.17);
  border-radius: 0 0 10px 10px;
  margin-bottom: 30px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 245px);
  @include large_device {
    margin-bottom: 20px;
    max-height: calc(100vh - 275px);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: #f2f6f8;
    border-radius: $border-radius;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c6c7;
    border-radius: $border-radius;
  }
  .table {
    margin-bottom: 0;
    &.no-dataSort {
      .sortable {
        .caret {
          display: none;
        }
      }
    }
    .sortable {
      cursor: pointer;
      .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
      .dropdown {
        .caret {
          margin: 10px 0 10px 5px;
          color: #cccccc;
        }
      }
      .dropup {
        .caret {
          margin: 10px 0;
          color: #cccccc;
          content: "";
          border-top: 0;
          border-bottom: 4px dashed;
        }
      }
    }
    td {
      line-height: 32px;
      font-size: 16px;
      padding: 10px 20px;
      min-width: 100px;
      @media screen and (max-width: 1366px) {
        line-height: 30px;
        font-size: 14px;
        padding: 8px;
      }
      @media screen and (max-width: 1200px) {
        min-width: 110px;
      }
      .topic-column {
        min-width: 120px;
      }
      .status-column {
        min-width: 160px;
      }
      .transNo-column {
        min-width: 150px;
      }
      .transDetail-column {
        min-width: 200px;
      }
    }
    tr,
    th,
    td {
      vertical-align: middle;
      border: none;
      color: $theme-custom-color;
    }
    th {
      background: #f2f2f2;
      color: #888888;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      padding: 10px 20px;
      @media screen and (max-width: 1366px) {
        font-size: 16px;
        padding: 10px;
        line-height: 24px;
      }
      @include large_device {
        line-height: 22px;
        font-size: 15px;
      }
      &.sortField {
        color: $theme-custom-color;
      }
    }
  }
  .react-bs-table-no-data {
    text-align: center;
  }
}
.react-bootstrap-table-pagination {
  justify-content: center;
  position: relative;
  .react-bs-table-sizePerPage-dropdown {
    .dropdown-toggle {
      background: $white-color;
      border-radius: 8px;
      border: none;
      color: #222222;
      margin-right: 10px;
      padding: 11px 13px;
      &::after,
      &::before {
        content: none;
      }
    }
  }
  .react-bootstrap-table-pagination-total {
    position: absolute;
    right: 15px;
    bottom: 0;
    z-index: 2;
    &.no-data {
      bottom: -35px;
    }
  }
  .react-bootstrap-table-pagination-list {
    position: relative;
    width: 100%;
    z-index: 1;
  }
}
.react-bootstrap-table-pagination-total {
  margin-bottom: 0;
  span {
    background: $white-color;
    padding: 0;
    border-radius: 8px;
    color: $theme-custom-color;
    font-size: 16px;
    line-height: 44px;
    font-weight: 400;
    margin: 0 5px;
    min-height: 44px;
    min-width: 44px;
    display: inline-block;
    text-align: center;
  }
}
@media screen and (max-width: 1199px) {
  .react-bootstrap-table-pagination {
    .react-bootstrap-table-pagination-total {
      position: relative;
      bottom: 18px;
      text-align: center;
      span {
        min-height: 35px;
        min-width: 35px;
        line-height: 35px;
        padding: 0;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .react-bootstrap-table-pagination {
    .react-bootstrap-table-pagination-total {
      bottom: 10px;
    }
  }
}
//modal
[class*="modal"] {
  .modal-dialog {
    .modal-content {
      border-radius: 10px;
      border: none;
      padding: 50px;
      .modal-header {
        padding: 0;
        position: absolute;
        top: -10px;
        right: -10px;
        width: 40px;
        height: 40px;
        z-index: 2;
        background: #fbd501;
        border-radius: 3px;
        justify-content: center;
        button {
          padding: 0;
          margin: 0;
          box-shadow: none;
        }
      }
      .modal-body {
        padding: 0;
        iframe {
          min-height: 450px;
          @include large_device {
            min-height: 300px;
          }
        }
        .modal-title {
          font-size: 24px;
          text-align: center;
          font-weight: bold;
          line-height: 40px;
          margin-bottom: 20px;
          text-transform: capitalize;
        }
        .modalBtn {
          display: flex;
          .btn {
            @include medium_device {
              width: calc(50% - 0px);
            }
          }
        }
      }
    }
  }
  &[aria-labelledby="promo-dialog"] {
    @media screen and (max-width: 575px) {
      left: 20px;
    }
    .modal-dialog {
      transform: none;
      margin: 0;
      right: 20px;
      left: auto;
      bottom: 120px;
      position: absolute;
      top: auto;
      max-width: 400px;

      @media screen and (max-width: 575px) {
        max-width: 90%;
        right: 40px;
        bottom: 100px;
      }

      .closeModal {
        top: -15px;
        right: -15px;
        color: rgba($white-color, 0.75);
        font-size: 30px;
        height: auto;
        width: auto;
        background: transparent;

        @media screen and (max-width: 575px) {
          top: -30px;
          right: -10px;
        }
      }
      .dialogInner {
        h3 {
          color: $white-color;

          @media screen and (max-width: 575px) {
            font-size: 20px;
            line-height: 25px;
          }

          span {
            color: $secondary-color;
          }
        }

        p {
          color: $white-color;
          margin-bottom: 10px;
          margin-top: 10px;

          @media screen and (max-width: 575px) {
            line-height: 22px;
          }
        }
        .frmOuter {
          @media screen and (max-width: 575px) {
            margin-top: 10px;
          }
        }
      }
    }
    .modal-content {
      padding: 15px 30px 10px;
      background-color: $theme-color;

      @media screen and (max-width: 575px) {
        padding: 25px 20px 10px;
      }
    }
    .modal-body {
      .responseWrap {
        margin-bottom: 15px;
      }
      .dialogInner {
        .frmOuter {
          input {
            padding: 15px 20px;
            width: 100%;
            color: $theme-custom-color;
            height: initial;

            @media screen and (max-width: 575px) {
              padding: 10px 20px;
            }
          }

          button {
            text-transform: uppercase;
            background-color: $secondary-color;
            color: $theme-color;
            height: initial;

            &:hover,
            &:focus {
              background-color: #ffe915;
            }
          }
        }
      }
    }
  }
}
.modal-backdrop {
  &.show {
    opacity: 0.7;
  }
  &.globalModalBackdrop {
    display: none;
    &.show {
      opacity: 0;
    }
  }
}

// slider dots and arrow css
.slick-slider {
  .slick-dots {
    bottom: -30px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    li {
      width: 10px;
      height: 10px;
      display: inline-block;
      margin: 0 3px;
      @include mobile_device {
        width: 8px;
        height: 8px;
      }
      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid $secondary-color;
        background-color: $white-color;
        font-size: 0;
        padding: 0;
        @include mobile_device {
          width: 8px;
          height: 8px;
        }
      }
      &.slick-active {
        button {
          background: $secondary-color;
        }
      }
    }
  }
}
.slick-arrow {
  z-index: 2;
  position: absolute;
  top: 50%;
  font-size: 0;
  background: transparent;
  border: none;
  padding: 0;
  &::before {
    content: "";
    width: 40px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    opacity: 1 !important;
    @include extra_large {
      width: 20px;
      background-size: 20px;
    }
  }
  &.slick-disabled {
    opacity: 0;
    display: none !important;
  }
  &.slick-prev {
    left: -80px;
    @include extra_large {
      left: -40px;
    }
    @include large_device {
      left: 0;
    }
    &::before {
      background-image: url($bg-base-path + "/left-arrow.svg");
      content: "";
    }
  }
  &.slick-next {
    right: -80px;
    @include extra_large {
      right: -40px;
    }
    @include large_device {
      right: 0;
    }
    &:before {
      content: "";
      background-image: url($bg-base-path + "/right-arrow.svg");
    }
  }
}

//header top spacing
.bodyMain {
  //margin-top: 80px;
  @include large_device {
    margin-top: 2rem;
  }
}
.nav {
  border-bottom: none;
}
.top-navbar {
  .header {
    padding: 80px 0;
    background-color: $white-color;
    position: relative;
    @include large_device {
      padding: 60px 0;
    }
    @include mobile_device {
      padding: 40px 0;
    }
  }
}

// tooltip
.tooltip {
  z-index: 5;
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    max-width: 500px;
    text-align: left;
    background: $white-color;
    color: $theme-custom-color;
    padding: 6px 15px;
    border: 1px solid #eef1f8;
  }
  .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.4rem;
    height: 0.8rem;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background: $white-color;
      border: 1px solid #eef1f8;
    }
  }
  &[x-placement="right"] {
    padding-left: 0.5rem;
    .tooltip-arrow {
      &::before {
        right: -5px;
        transform: rotate(45deg);
        border-width: 0 0 1px 1px;
      }
    }
  }
  &[x-placement="left"] {
    padding-right: 0.5rem;
    .tooltip-arrow {
      right: 5px;
      left: auto;
      &::before {
        right: 0;
        transform: rotate(45deg);
        border-width: 1px 1px 0 0;
      }
    }
  }
}

// navbar brand
.navbar-brand {
  font-weight: 500;
  font-size: 25px;
  color: $theme-color;
  padding: 0;
  margin-right: 0;
  position: relative;
  line-height: normal;
  .desklogo {
    height: 40px;
  }
  &:focus-visible {
    outline: none;
  }
  &:hover {
    color: $theme-color;
  }
  .logoDiv {
    img {
      width: 40px !important;
      height: 40px !important;
      border-radius: 50%;
    }
  }
  span {
    color: #e4c100;
  }
  .flag {
    margin-left: 5px;
  }
}

// react pagination
input:-internal-autofill-selected {
  background: $white-color;
}
.table > :not(:first-child) {
  border-top: none;
}


// pagination css
.pagePagination {
  @include mobile_device {
    margin-left: 7px;
    margin-right: 7px;
  }
  .pagination {
    .page-item {
      &.active {
        a {
          font-weight: bold;
        }
      }
      &.disabled {
        a {
          pointer-events: none;
          &:hover {
            cursor: default;
          }
        }
      }
      &.dots {
        line-height: 1;
      }
      a {
        span[aria-hidden="true"] {
          font-size: 0;
          position: relative;
          &::before {
            content: "";
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            background-size: 20px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -70%);
            transform: translate(-50%, -70%);
          }
        }
        &[aria-label="Previous"] {
          span[aria-hidden="true"] {
            &::before {
              background-image: url($bg-base-path + "/prev.svg");
            }
          }
        }
        &[aria-label="Next"] {
          span[aria-hidden="true"] {
            &::before {
              background-image: url($bg-base-path + "/next.svg");
            }
          }
        }
        &.page-link-next,
        &.page-link-prev {
          font-size: 0;
          position: relative;
          &::before {
            content: "";
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            background-size: 20px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
        }
        &.page-link-prev {
          &::before {
            background-image: url($bg-base-path + "/prev.svg");
          }
        }
        &.page-link-next {
          &::before {
            background-image: url($bg-base-path + "/next.svg");
          }
        }
      }
    }
  }
}

//order pagination
.pagination {
  @include mobile_device {
    flex-wrap: wrap;
  }
  justify-content: center;
  .page-item {
    margin: 0 6px;
    @include mobile_device {
      margin: 0 4px;
    }
    @include extra_small {
      margin: 0 1px;
    }
    &.active,
    &:hover {
      .page-link {
        background: #fbd501;
        color: $theme-color;
        font-weight: 700;
      }
    }
    .page-link {
      border: none;
      background-color: transparent;
      border-radius: 8px !important;
      color: $theme-custom-color;
      font-size: 16px;
      line-height: 44px;
      font-weight: 400;
      min-height: 44px;
      min-width: 44px;
      text-align: center;
      height: 44px;
      padding: 0;
      position: relative;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      @include mobile_device {
        padding: 0;
        font-size: 14px;
        line-height: 37px;
        min-width: 35px;
        min-height: 35px;
        height: 35px;
      }
      @include extra_small {
        font-size: 13px;
        line-height: 30px;
        min-width: 30px;
        min-height: 30px;
        height: 30px;
      }
      &[title="next page"] {
        .page-link {
          font-size: 0;
        }
      }
      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &[aria-label="Previous"],
      &[aria-label="First"],
      &[aria-label="Next"],
      &[aria-label="Last"] {
        padding-left: 10px;
        padding-right: 10px;
        span {
          font-size: 25px;
        }
      }
      .sr-only {
        display: none;
      }
    }
  }
  &.react-bootstrap-table-page-btns-ul {
    .page-item {
      .page-link {
        &::before {
          content: "";
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          background-size: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
      &[title="previous page"] {
        .page-link {
          background-color: transparent;
          font-size: 0;
          &::before {
            background-image: url($bg-base-path + "/prev.svg");
          }
        }
      }
      &[title="next page"] {
        .page-link {
          background-color: transparent;
          font-size: 0;
          &::before {
            background-image: url($bg-base-path + "/next.svg");
          }
        }
      }
    }
  }
}

// spinner
.spinner-border {
  width: 30px;
  height: 30px;
  border-width: 3px;
  border-color: $theme-color;
  border-right-color: transparent;
  span {
    visibility: hidden;
  }
}
.theme-btn {
  .spinner-border {
    border-color: $white-color;
    border-right-color: transparent;
  }
}

.bg-white {
  background-color: $white-color !important;
}

// toast
.Toastify__toast {
  &.Toastify__toast--success {
    background: #006603;
  }
  &.Toastify__toast--error {
    background: #971d10;
  }
}

.danger {
  color: #e41515;
}
.success {
  color: #0b9c33;
}

//recore not found
.noReview-found {
  text-align: center;
  font-size: 20px;
  margin-top: 40px;
  color: $theme-custom-color;
  text-transform: capitalize;
}
.selectMenu {
  @include medium_device {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .selectInput__value-container {
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: #e6eaec;
      width: 1px;
      height: 23px;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  .selectInput__control {
    width: 240px;
    margin: 0 auto;
    border-radius: 0;
  }
}
.calculateOrder,
.card-details {
  .selectInput__control {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    background: #f2f6f8;
    position: relative;
    border-radius: $border-radius;
    &::after {
      content: "";
      background-image: url($bg-base-path + "/dropdown-toggle.svg");
      width: 12px;
      height: 6px;
      position: absolute;
      background-repeat: no-repeat;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      background-size: contain;
      right: 15px;
      border: none;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }
    &.selectInput__control--menu-is-open {
      &:after {
        -webkit-transform: translateY(-50%) rotate(180deg);
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .selectInput__value-container {
      padding: 0 15px;
      ~ div {
        margin: 0;
        padding: 0;
      }
    }
    .selectInput__single-value {
      font-size: 16px;
      font-weight: 400;
      color: $theme-custom-color;
    }
  }
  .selectInput__placeholder {
    color: $theme-custom-color;
    font-size: 16px;
    font-weight: 400;
  }
  .selectInputDeadline {
    .selectInput__menu {
      top: auto;
      bottom: 35px;
    }
  }
  .selectInput__menu {
    margin-top: 4px;
    border: 1px solid #f2f6f8;
    z-index: 2;
    box-shadow: 0 7px 20px 0px rgba(38, 56, 77, 0.06);
    .selectInput__menu-list {
      padding: 0;
      @media only screen and (max-width: 767px) {
        max-height: 200px;
      }
      &::-webkit-scrollbar {
        width: 5px;
        background: #f2f6f8;
        border-radius: $border-radius;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c4c6c7;
        border-radius: $border-radius;
      }
      .selectInput__group-heading {
        padding-left: 15px;
        padding-right: 15px;
      }
      .selectInput__option {
        font-size: 16px;
        border-bottom: 1px solid #f0f4f6;
        color: $theme-custom-color;
        padding: 8px 15px;
        font-weight: 400;
        line-height: 24px;
        &:hover,
        &.selectInput__option--is-focused {
          background-color: #f2f6f8;
        }
        &.selectInput__option--is-selected {
          background-color: #f2f6f8;
          color: $theme-custom-color;
          font-weight: 500;
        }
      }
    }
  }
}
.writingGroup {
  .btn-check {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    &:focus,
    & + .btn {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  input[type="radio"] {
    &:checked {
      & + .btn {
        background-color: $white-color;
        border-color: $secondary-color;
      }
    }
  }
}
.loader {
  background: $white-color;
  padding: 20px;
  max-height: calc(100% - 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
}
//Search common order/balance tabs css
.searchBlock {
  position: absolute;
  right: 160px;
  top: -54px;
  display: -ms-flexbox;
  display: flex;
  @include large_device {
    top: 65px;
    right: 10px;
    position: fixed;
    z-index: 5;
  }
  &::before {
    content: "";
    background-image: url($bg-base-path + "/order/search.svg");
    width: 18px;
    height: 18px;
    left: 15px;
    background-size: 18px;
    display: inline-block;
    position: absolute;
    z-index: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    @include large_device {
      content: none;
    }
  }
  .formcontrol {
    background: $white-color;
    font-size: 14px;
    line-height: 28px;
    color: $theme-custom-color;
    width: 300px;
    padding: 6px 15px 6px 40px;
    border-radius: 6px 0 0 6px;
    @media screen and (max-width: 1600px) {
      width: 280px;
    }
    @include extra_large {
      width: 150px;
    }
    @include large_device {
      padding: 2px 15px;
      border-radius: 6px;
      width: 300px;
    }
    @include mobile_device {
      width: 250px;
    }
    &::placeholder {
      color: #888;
      font-size: 14px;
    }
  }
  .btn {
    border-radius: 0 6px 6px 0 !important;
    width: auto;
    padding: 10px 20px;
    line-height: 20px;
    &:hover {
      &::before {
        content: none;
      }
    }
    @include large_device {
      border-radius: 0 6px 6px 0 !important;
      width: auto;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 0 10px;
      border: 1px solid #fff;
    }
  }
}
.watchVideoDemo {
  [class*="modal-content"] {
    padding: 10px !important;
    .howToOrderVideo {
      position: relative;
      @include desktop_up {
        height: 450px;
        iframe {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}