$body-color: #f4f8fd;
$theme-color: #12245a;
$secondary-color: #fbd501;
$font-family: 'Roboto', sans-serif;
$border-radius: 6px;
$white-color: #ffffff;
$black-color: #000000;
$theme-custom-color: #151515;
$desc-font: #5f6368;
$bg-base-path: "../public/";

@mixin desktop_up {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin up_large {
  @media (max-width: 1440px) {
    @content;
  }
}
@mixin extra_large {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin extra_large_device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}
@mixin large_device {
  @media (max-width: 991px) {
    @content;
  }
}
@mixin medium_device {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin mobile_device {
  @media (max-width: 575px) {
    @content;
  }
}
@mixin extra_small {
  @media (max-width: 420px) {
    @content;
  }
}
@mixin iphone_xr {
  @media (device-width: 414px) {
    @content;
  }
}
